import { api } from "../../services/api";
import { toast } from "react-toastify";

export async function fetchPagar(idsala, selectedNumbers) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("@sistemabolao:token");

    // Convertendo idsala para número
    const numericIdsala = parseInt(idsala);
    api
      .post(
        "/api/v1/pagar/jogo",
        {
          idsala: idsala,
          numeros: selectedNumbers,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data);
        reject(error.response.data);
      });
  });
}
