import React, { useState } from "react";
import Menu from "../../../components/menu";
import HistoricoJogosComponent from "../../../components/tabelas/HistoricoJogos";
import HistoricoJogosFinalizadosComponent from "../../../components/tabelas/JogosFinalizados";

function MobileView() {
  const columnHeight = {
    col00: "5%",
    col0: "7%",
    col1: "100%",
    col2: "75%",
    col3: "75%",
    col4: "20%",
  };

  const columnWidth = {
    col0: "20%",
    col1: "100%",
    col2: "30%",
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="flex h-screen bg-[#262626] ">
      {/* Menu lateral */}
      <nav
        className={`bg-gradient-to-tl from-[#9751eb] to-[#f8565e] text-white py-4 w-full md:w-64 fixed inset-y-0 left-0 z-10 ${
          isMenuOpen ? "block" : "hidden"
        } md:block`}
      >
        <Menu />
      </nav>

      {/* Botão do Menu (aparece apenas em telas pequenas) */}
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="md:hidden fixed p-5 top-4 right-4   bg-[#262626] text-white px-4 py-2 rounded-lg z-20"
      >
        {isMenuOpen ? "Fechar Menu" : "Abrir Menu"}
      </button>

      {/* Conteúdo do Dashboard */}
      {/* Coluna do conteúdo */}
      <div
        className="w-full md:w-auto bg-gradient-to-tr from-[#9751eb] to-[#f8565e]   border-l-2 border-gray-200"
        style={{ height: columnHeight.col1, width: columnWidth.col1 }}
      >
        <div className="grid grid-cols-1 p-2 w-full gap-1 ">
          <HistoricoJogosFinalizadosComponent />
        </div>

        <div className="grid grid-cols-1 p-2 w-full gap-1 ">
          <HistoricoJogosComponent />
        </div>
      </div>
    </div>
  );
}

function DesktopView() {
  const columnHeight = {
    col00: "5%",
    col0: "7%",
    col1: "100%",
    col2: "75%",
    col3: "75%",
    col4: "20%",
  };

  const columnWidth = {
    col0: "20%",
    col1: "100%",
    col2: "30%",
  };

  return (
    <div className="flex flex-col  h-screen">
      {/* Colunas do meio */}
      <div className="flex flex-grow">
        {/* Coluna do menu */}

        <div className="w-full md:w-auto bg-gradient-to-tl from-[#D3D3D3] to-[#e4e4e4]  ">
          <Menu />
        </div>

        {/* Coluna do conteúdo */}
        <div
          className="w-full md:w-auto bg-gradient-to-tr from-[#979797] to-[#979797]   border-l-2 border-gray-200"
          style={{ height: columnHeight.col1, width: columnWidth.col1 }}
        >
          <div className="grid grid-cols-1 p-2 w-full gap-1 ">
            <div
              className="bg-gradient-to-tl from-[#D3D3D3] to-[#e4e4e4] p-4 rounded-md shadow-md"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <h2 className="text-[#656565] font-bold text-[25px]">JOGOS</h2>
            </div>
          </div>

          <div className="grid grid-cols-1 p-2 w-full gap-1 ">
            <HistoricoJogosComponent />
          </div>

          <div className="grid grid-cols-1 p-2 w-full gap-1 ">
            <HistoricoJogosFinalizadosComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

function Index() {
  return (
    <div>
      <div className="lg:hidden">
        <MobileView />
      </div>
      <div className="hidden lg:block">
        <DesktopView />
      </div>
    </div>
  );
}

export default Index;
