import React, { useState } from "react";
import { useAuth } from "../../../hooks/Auth";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { FiUnlock, FiEdit } from "react-icons/fi";
import { BiPhone, BiLockAlt } from "react-icons/bi";

export default function Login() {
  const nomedosistema = "SISTEMA BOLÃO";

  const { signAdmin, error } = useAuth();
  const [user, setUser] = useState({
    telefone: "",
    senha: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  function handleChange(event) {
    const { name, value } = event.target;

    setUser((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function handleTogglePassword() {
    setShowPassword(!showPassword);
  }

  async function handleWithSubmit(e) {
    e.preventDefault(); // Previne o comportamento padrão do formulário

    if (user.telefone === "") {
      return null;
    }

    if (user.senha === "") {
      return null;
    }

    await signAdmin(user);
  }

  const ExibirSenha = () => {
    // Your function to toggle password visibility
  };

  const esqueceu_senha = "#";
  const criar_conta = "#";

  return (
    <>
      <div className="min-h-screen flex items-center justify-center bg-[#2F3136]">
        <div className="bg-[#202225] text-white border-2 border-[#f1d182] rounded-2xl w-96 mt-12 p-5 inline-block">
          <div className="text-center p-3">
            <img className="w-40 -mt-2 mx-auto" src="logo.png" alt="Logo" />
            <h1 className="font-bold uppercase mb-7 text-[25px] text-white-900 h4">
              <i>Bolao Prêmios</i>
            </h1>

            <div className="form-group mb-4">
              <div className="flex items-center   bg-[#ffffff] border-2 none border-[#656565] py-2 px-3 rounded-2xl mb-4">
                <BiPhone className="h-5 w-5 text-[#2F3136]" />

                <input
                  required
                  onChange={handleChange}
                  value={user.telefone}
                  type="number"
                  name="telefone"
                  id="telefone"
                  className="pl-2 bg-transparent placeholder-slate-400 ml-2 w-full  bg-[#535353] outline-none border-none rounded text-[#202225]"
                  placeholder="Telefone"
                />
              </div>
            </div>

            <div className="form-group mb-4">
              <div className="flex items-center   bg-[#ffffff] border-2 none border-[#656565] py-2 px-3 rounded-2xl mb-4">
                <BiLockAlt className="h-5 w-5 text-[#2F3136]" />

                <input
                  required
                  className="pl-2 bg-transparent placeholder-slate-400 ml-2 w-full  bg-[#535353] outline-none border-none rounded text-[#202225]"
                  placeholder="Senha"
                  onChange={handleChange}
                  value={user.password}
                  type={showPassword ? "text" : "password"}
                  name="senha"
                  id="senha"
                />
                {showPassword ? (
                  <RiEyeOffFill
                    className="h-5 w-5 text-[#2F3136] ml-2 cursor-pointer"
                    onClick={handleTogglePassword}
                  />
                ) : (
                  <RiEyeFill
                    className="h-5 w-5 text-[#2F3136] ml-2 cursor-pointer"
                    onClick={handleTogglePassword}
                  />
                )}
              </div>
            </div>

            <button
              id="enviar_login"
              className="bg-[#f1d182] font-bold text-[15px]  text-[#5c4a1d] rounded-md w-full p-3"
            >
              ENTRAR
            </button>

            <div className="mt-4 text-center text-[#efefef]">
              <a
                style={{ fontSize: "14px", color: "#cddaff" }}
                className="small"
                href={esqueceu_senha}
              >
                Esqueceu sua senha?
              </a>
            </div>

            <hr className="bg-[#44525D]" />

            <div className="mt-8 cursor-pointer">
              <a href={criar_conta}>
                <div className="bg-[#57ae53]   text-[15px]  rounded-md w-full p-3">
                  Criar uma conta
                </div>
              </a>
            </div>

            {error && (
              <div className="text-center my-5 bg-red-500 border border-slate-100 rounded p-2">
                <div className="text-[13px]  text-slate-100">{error}</div>
              </div>
            )}
            <h1 className="text-[#efefef] mt-5 text-center text-[10px]  ">
              Copyright © 2024 {nomedosistema}
            </h1>
            <h1 className="text-[#efefef]  text-center text-[10px]  ">
              Todos os direitos reservados
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}
