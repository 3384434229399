import React, { useState, useEffect } from "react";
import { fintechMeusJogos } from "../../integration/jogos/meusjogos/fintechMeusJogos";
import { formatToBRL } from "brazilian-values";
import { useNavigate } from "react-router-dom";

const TableExample = () => {
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    funcLista();
  }, []);

  const [respp, setResp] = useState([]);
  const [error, setError] = useState(null);
  const [selectedCotas, setSelectedCotas] = useState([]);

  async function funcLista() {
    try {
      const resp = await fintechMeusJogos();
      setResp(resp);
      setError(null);
    } catch (erro) {
      setResp(null);
      setError(erro);
    }
  }

  const getStatusClass = (status) => {
    switch (status) {
      case "Pagamento Pendente":
        return "bg-yellow-100 text-yellow-800";
      case "Pagamento Aprovado":
        return "bg-green-100 text-green-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const handleRepetirJogo = (index) => {
    localStorage.removeItem("@sistemabolao:repetirjogo");

    const selectedJogo = respp[index];
    const cotas = [];

    for (let i = 1; i <= 10; i++) {
      const cotaValue = selectedJogo[`cota_${i}`];
      if (cotaValue !== null) {
        cotas.push(cotaValue);
      }
    }

    setSelectedCotas(cotas);

    localStorage.setItem("@sistemabolao:repetirjogo", cotas);

    // Redirecionamento de página
    navigate(`/jogos`);
  };

  return (
    <div
      className="bg-gradient-to-tl from-[#D3D3D3] to-[#e4e4e4] p-4 rounded-md shadow-md"
      style={{ maxHeight: "400px", overflowY: "auto" }}
    >
      <div>
        <h2 className="text-[#656565] text-[15px] font-bold  ">
          Histórico dos Meus Jogos
        </h2>
        <p className="text-[#888888] text-[14px] mb-5">
          Explore o histórico completo dos jogos que você jogou.
        </p>
      </div>

      {error && (
        <div className="text-center my-5 bg-[#656565] border border-slate-100 rounded p-2">
          <div className="text-[13px] text-slate-100">{error}</div>
        </div>
      )}

      {!error && respp && respp.length > 0 && (
        <table className="w-full table-auto rounded-sm bg-[#fbfbfb] text-[13px] text-center text-[#515151]">
          <thead>
            <tr className="bg-[#bebebe]">
              <th className="border px-4 py-2">-</th>
              <th className="border px-4 py-2">Status</th>
              <th className="border px-4 py-2">Nome do Jogo</th>
              <th className="border px-4 py-2">Valor do Jogo</th>
              {[...Array(10)].map((_, index) => (
                <th key={index} className="border px-4 py-2">
                  Cota
                </th>
              ))}
              <th className="border px-4 py-2">Data de Pagamento</th>
              <th className="border px-4 py-2">Ação</th>
            </tr>
          </thead>
          <tbody>
            {respp.map((item, index) => (
              <tr key={index} className="hover:bg-gray-200">
                <td className="border px-4 py-2">{index + 1}</td>
                <td
                  className={`border px-4 py-2 ${getStatusClass(
                    item.status_jogo
                  )}`}
                >
                  {item.status_jogo}
                </td>
                <td className="border px-4 py-2">{item.nome_jogo}</td>
                <td className="border px-4 py-2">
                  {formatToBRL(item.valor_jogo) || ""}
                </td>
                {[...Array(10)].map((_, cotaIndex) => (
                  <td
                    key={cotaIndex}
                    className={`border px-4 py-2 ${
                      item[`cota_${cotaIndex + 1}`] === null
                        ? "bg-gray-200"
                        : ""
                    }`}
                  >
                    {item[`cota_${cotaIndex + 1}`] !== null
                      ? item[`cota_${cotaIndex + 1}`]
                      : "-"}
                  </td>
                ))}
                <td className="border px-4 py-2">{item.data_pagamento}</td>
                <td className="border px-4 py-2">
                  <button
                    onClick={() => handleRepetirJogo(index)}
                    className="bg-[#656565] text-white px-2 py-1 rounded hover:bg-gray-400"
                  >
                    Repetir Jogo
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TableExample;
