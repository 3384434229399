import { useState, createContext, useEffect } from "react";

export const SidebarContext = createContext({});

export function SidebarProvider({ children }) {
  const [selectedItem, setSelectedItem] = useState("home");

  const [isSubMenuFinanceiro, setIsSubMenuFinanceiro] = useState(false);
  const [isSubMenuConfiguracao, setIsSubMenuConfiguracao] = useState(false);
  const [isSubMenuContrato, setIsSubMenuContrato] = useState(false);
  const [isSubMenuUsuarios, setIsSubMenuUsuarios] = useState(false);
  const [isSubMenuCompliance, setIsSubMenuCompliance] = useState(false);

  useEffect(() => {
    if (isSubMenuFinanceiro) {
      setIsSubMenuFinanceiro(true);
    }
  }, [isSubMenuFinanceiro]);

  return (
    <SidebarContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        isSubMenuFinanceiro,
        setIsSubMenuFinanceiro,
        isSubMenuConfiguracao,
        setIsSubMenuConfiguracao,
        isSubMenuContrato,
        setIsSubMenuContrato,

        isSubMenuUsuarios,
        setIsSubMenuUsuarios,

        isSubMenuCompliance,
        setIsSubMenuCompliance,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}
