import React from "react";

function DisplayPage() {
  return (
    <main className="min-h-screen bg-[#101010] flex flex-col justify-center items-center px-6 sm:px-12 lg:px-24">
      <h1 className="text-4xl sm:text-6xl lg:text-9xl font-extrabold text-white tracking-widest text-center mb-8">
        INFO
      </h1>
      <div className="text-white text-center">
        <h1 className="text-lg sm:text-2xl lg:text-3xl font-bold mb-4">
          Acesso não permitido!
        </h1>
        <p className="text-base sm:text-lg lg:text-xl">
          Por favor, acesse a plataforma fora do mobile. Utilize um navegador
          web em seu computador para acessar a plataforma. Recomendamos o uso do
          Google Chrome, Mozilla Firefox ou Safari.
        </p>
      </div>
    </main>
  );
}

export default DisplayPage;
