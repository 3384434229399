import React, { useState } from "react";
import { fetchAlterarSenha } from "../../integration/alterarsenha/fetchAlterarSenha";

const MeuPerfil = () => {
  const [error, setError] = useState("");
  const [sucess, setSucess] = useState("");

  const [novasenha, setNovasenha] = useState("");
  const [repetirsenha, setRepetirsenha] = useState("");

  const handleValorChange = (e) => {
    setNovasenha(e.target.value);
  };

  const handleWalletChange = (e) => {
    setRepetirsenha(e.target.value);
  };

  async function funcAlterarSenha() {
    try {
      const resp = await fetchAlterarSenha(novasenha, repetirsenha);
      setError("");
      setSucess(resp);
    } catch (erro) {
      setSucess("");
      setError(erro);
    }
  }

  return (
    <div className="bg-gradient-to-tl text-[#656565] from-[#D3D3D3] to-[#e4e4e4] p-4 rounded-md shadow-md">
      <>
        <h2 className="  text-[15px] font-bold ">Alterar Dados</h2>
        <h2 className="  text-[15px]  mb-4">
          Insira as informações abaixo para alterar a senha.
        </h2>
      </>

      {error && (
        <div className="text-center my-5 bg-red-500 border border-slate-100 rounded p-2">
          <div className="text-[13px]  text-slate-100">{error}</div>
        </div>
      )}

      {sucess && (
        <div className="text-center my-5 bg-green-400 border border-slate-100 rounded p-2">
          <div className="text-[13px]  text-slate-100">{sucess}</div>
        </div>
      )}
      {!sucess && (
        <div>
          <div className="mb-4 text-[15px]">
            <label htmlFor="valorInvestido" className="block mb-2">
              Nova Senha:
            </label>
            <input
              type="text"
              className="border p-2 rounded-md w-full"
              value={novasenha}
              onChange={handleValorChange}
              placeholder="Nova Senha"
            />
            <label htmlFor="valorInvestido" className="block mt-2 mb-2">
              Repetir Senha:
            </label>
            <input
              type="text"
              className="border p-2 rounded-md w-full"
              value={repetirsenha}
              onChange={handleWalletChange}
              placeholder="Repetir Senha"
            />
          </div>

          <button
            className="mt-2 text-[15px] bg-[#656565]  text-white font-bold py-2 px-4 rounded "
            onClick={funcAlterarSenha}
          >
            Alterar Senha
          </button>
        </div>
      )}
    </div>
  );
};

export default MeuPerfil;
