export default function Index() {
  return (
    <main class="h-screen w-full flex flex-col justify-center items-center bg-[#171923]">
      <h1 class="text-9xl font-extrabold text-gray-400 tracking-widest">404</h1>
      <div class="bg-[#f9f9f9] text-black px-2 text-sm rounded rotate-12 absolute">
        Página não encontrada
      </div>
      <button class="mt-5">
        <a class="relative inline-block text-sm font-medium text-slate-400 group active:text-slate-500 focus:outline-none focus:ring">
          <span class="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-gray-400 group-hover:translate-y-0 group-hover:translate-x-0"></span>

          <span class="relative block text-gray-700 px-8 py-3 bg-gray-400 border border-current">
            <a href="/dashboard">Página Inicial</a>
          </span>
        </a>
      </button>
    </main>
  );
}
