// NumberSelector.js
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { BiMoneyWithdraw } from "react-icons/bi";
import { fetchPagar } from "../../integration/pagar/fetchPagar";

const NumberSelector = (idsala) => {
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [randomMode, setRandomMode] = useState(false);
  const [randomCount, setRandomCount] = useState(10);
  const [carregando, setCarregando] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const maxSelection = 10; // Variável para controlar a quantidade máxima de números que podem ser selecionados

  const [errorr, setError] = useState("");

  const handleNumberClick = (number) => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter((num) => num !== number));
    } else if (selectedNumbers.length < maxSelection) {
      setSelectedNumbers([...selectedNumbers, number]);
    }
  };

  const handleRandomMode = () => {
    setRandomMode(true);
    const randomNumbers = [];
    while (randomNumbers.length < randomCount) {
      const randomNum = Math.floor(Math.random() * 25) + 1;
      if (!randomNumbers.includes(randomNum)) {
        randomNumbers.push(randomNum);
      }
    }
    setSelectedNumbers(randomNumbers);
  };

  const resetSelection = () => {
    setSelectedNumbers([]);
    setRandomMode(false);
    funcLimparRepetir();
  };

  async function funcLimparRepetir() {
    localStorage.removeItem("@sistemabolao:repetirjogo");
  }

  const openModal = () => {
    funcLimparRepetir();
    setCarregando(true);
    setModalIsOpen(true);
    funcPagar();
    //api pagar
    async function funcPagar() {
      try {
        const resp = await fetchPagar(idsala, selectedNumbers);
        setError("");
      } catch (erro) {
        setError(erro);
        setModalIsOpen(false);
      }
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const copyToClipboard = () => {
    const copyText = document.getElementById("pixCode").innerText;
    navigator.clipboard.writeText(copyText).then(
      () => {
        alert("Código PIX copiado para a área de transferência!");
      },
      () => {
        alert("Falha ao copiar o código PIX.");
      }
    );
  };

  useEffect(() => {
    const verificarnumerocopiado = localStorage.getItem(
      "@sistemabolao:repetirjogo"
    );
    if (verificarnumerocopiado) {
      const cotas = verificarnumerocopiado.split(",").map(Number);
      setSelectedNumbers(cotas);
    }
  }, []);

  return (
    <div className="grid grid-cols-2 p-2 w-full gap-2">
      <div className="flex flex-col items-center space-y-4 p-4 rounded-md shadow-md bg-gradient-to-tl from-[#D3D3D3] to-[#e4e4e4]">
        <h2 className="text-[20px] font-semibold text-[#656565]">
          Selecione seus números da sorte!
        </h2>
        <p className="text-[15px] text-[#656565]">
          Escolha de 1 a {maxSelection} números agora e tenha a chance de ganhar
          grandes prêmios!
        </p>

        <div className="grid grid-cols-5 gap-2">
          {Array.from({ length: 25 }, (_, i) => i + 1).map((number) => (
            <button
              key={number}
              className={`w-12 h-12 flex items-center justify-center border rounded-lg ${
                selectedNumbers.includes(number)
                  ? "bg-green-400 text-white"
                  : "bg-[#b1b1b1] text-white"
              }`}
              onClick={() => handleNumberClick(number)}
              disabled={randomMode}
            >
              {number}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-1 p-2 w-full gap-1 items-center justify-center">
          <button
            className="px-4 py-2 bg-[#656565] text-white rounded-lg"
            onClick={handleRandomMode}
            disabled={randomMode}
          >
            Modo Aleatório
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded-lg"
            onClick={resetSelection}
          >
            Resetar
          </button>
        </div>
      </div>

      <div className="flex flex-col items-center space-y-4 p-4 rounded-md shadow-md bg-gradient-to-tl from-[#D3D3D3] to-[#e4e4e4]">
        <h2 className="text-[20px] font-semibold text-[#656565]">
          Números Selecionados: {selectedNumbers.length}
        </h2>

        {selectedNumbers.length > 0 && (
          <div className="p-4 bg-gradient-to-r from-green-400 to-green-500 rounded-lg shadow-md text-white text-xl font-semibold">
            <p className="text-base leading-6 text-white">
              {selectedNumbers.join(", ")}
            </p>
          </div>
        )}
        {selectedNumbers.length > 0 && (
          <div className="flex items-center bg-green-400 text-white rounded-lg p-2 w-full">
            <button
              className="flex items-center justify-center flex-1 px-4 py-2"
              onClick={openModal}
            >
              <BiMoneyWithdraw className="text-2xl mr-2" />
              PAGAR
            </button>
          </div>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="QRCode PIX Modal"
        className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        {carregando && (
          <div className="flex items-center justify-center bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
            <p className="ml-6 text-[15px] text-gray-700">
              Estamos preparando seu QR Code PIX. Aguarde enquanto processamos o
              pagamento...
            </p>
          </div>
        )}

        {!carregando && (
          <div className="grid grid-cols-1 p-2 bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
            <h2 className="text-2xl font-bold mb-4">Pague com PIX</h2>

            <p id="pixCode" className="mt-4 text-lg">
              Código PIX: codigo_pix_aqui
            </p>
            <button
              className="px-4 py-2 bg-gray-400 text-white rounded-lg mt-4"
              onClick={copyToClipboard}
            >
              Copiar código
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded-lg mt-4"
              onClick={closeModal}
            >
              Fechar
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default NumberSelector;
