import React from "react";

const Slider = ({ title, descricao, images }) => {
  return (
    <a class="flex flex-col  items-center bg-white  rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
      <img
        class="object-cover w-full border border-stone-300 rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
        src={images}
        alt=""
      />
      <div class="flex text-[15px]  flex-col justify-between p-4 leading-normal">
        <h5 class="mb-2  font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h5>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {descricao}
        </p>
      </div>
    </a>
  );
};

export default Slider;
