import React, { useState } from "react";
import Menu from "../../../components/menu";
import Tabelas from "../../../components/tabelas/MeusJogos";
import MensagemComponent from "../../../components/mensagem/index";
import ComprarComponent from "../../../components/comprarcomponent/Index";
import SlidComponent from "../../../components/slid/Index";
import foto01 from "../../../assets/slids/01.jpg";
import foto02 from "../../../assets/slids/02.jpg";
import foto03 from "../../../assets/slids/03.jpg";
import foto04 from "../../../assets/slids/04.jpg";
import foto05 from "../../../assets/slids/05.jpg";
import foto06 from "../../../assets/slids/06.jpg";
import foto07 from "../../../assets/slids/07.jpg";
import foto08 from "../../../assets/slids/08.jpg";
import foto09 from "../../../assets/slids/09.jpg";
import foto10 from "../../../assets/slids/10.jpg";
import foto11 from "../../../assets/slids/11.jpg";

const titleslid01 = "1º Passo";
const descricaoslid01 = "Criação da Wallet";

const titleslid02 = "2º Passo";
const descricaoslid02 = "Escolha a opção: Criar uma nova Carteira";

const titleslid03 = "3º Passo";
const descricaoslid03 = "Escolha a opção: Fase Secreta";

const titleslid04 = "4º Passo";
const descricaoslid04 =
  "Considere cuidadosamente como você armazena suas chaves. Lembre-se: é vital mantê-las seguras e não perdê-las.";

const titleslid05 = "5º Passo";
const descricaoslid05 = "Selecione todas as opções e aperte em continuar.";

const titleslid06 = "6º Passo";
const descricaoslid06 = "Pronto, sua wallet esta criada";

const titleslid07 = "7º Passo";
const descricaoslid07 =
  "Agora precisamos preparar sua Wallet para receber os BTC, deslize a tela para baixo até encontrar a opção (Gerenciar Cripto).";

const titleslid08 = "8º Passo";
const descricaoslid08 =
  "Digine na aba de pesquisa FTM,e selecione conforme a imagem.";

const titleslid09 = "9º Passo";
const descricaoslid09 =
  "Digine na aba de pesquisa BTC (fantom),e selecione conforme a imagem.";

const titleslid10 = "10º Passo";
const descricaoslid10 =
  "Tudo pronto, agora você consegue receber seus BTC em sua Wallet, Selecione o BTC (fantom) para abrir as opções de receber a criptomoeda.";

const titleslid11 = "11º Passo";
const descricaoslid11 =
  "Na tela atual, copie o endereço da carteira, pois precisaremos dele para enviar os BTC. Tudo Pronto, quando for comprar a ordem insira esse endereço!";

function MobileView() {
  const columnHeight = {
    col00: "5%",
    col0: "7%",
    col1: "100%",
    col2: "75%",
    col3: "75%",
    col4: "20%",
  };

  const columnWidth = {
    col0: "20%",
    col1: "100%",
    col2: "30%",
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="flex h-screen bg-[#262626] ">
      {/* Menu lateral */}
      <nav
        className={`bg-gradient-to-tl from-[#9751eb] to-[#f8565e]   text-white py-4 w-full md:w-64 fixed inset-y-0 left-0 z-10 ${
          isMenuOpen ? "block" : "hidden"
        } md:block`}
      >
        <Menu />
      </nav>

      {/* Botão do Menu (aparece apenas em telas pequenas) */}
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="md:hidden fixed top-4 right-4 bg-[#373738] text-white px-4 py-2 rounded-lg z-20"
      >
        {isMenuOpen ? "Fechar Menu" : "Abrir Menu"}
      </button>

      {/* Conteúdo do Dashboard */}
      <div
        className="w-full md:w-auto bg-gradient-to-tr from-[#9751eb] to-[#f8565e]   border-l-2 border-gray-200"
        style={{ height: columnHeight.col1, width: columnWidth.col1 }}
      >
        <div className="grid grid-cols-1 p-2 w-full gap-1 ">
          <MensagemComponent />
        </div>
        <div className="grid grid-cols-1 p-2 w-full gap-1 ">
          <ComprarComponent />
        </div>

        <div className="grid grid-cols-1 p-2 gap-1 ">
          <Tabelas />
        </div>

        <div className="grid grid-cols-1 p-2   gap-1 my-2 bg-slate-300">
          <h5 className="my-2 text-[15px] font-bold tracking-tight text-slate-600 dark:slate-600">
            Tutorial de Como receber os BTC
          </h5>
          <p className="text-base text-slate-600 dark:text-gray-400">
            Sugerimos que baixe o{" "}
            <a
              href="https://trustwallet.com/pt-PT"
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-600 font-bold  "
            >
              Trust Wallet
            </a>{" "}
            para iOS ou Android.
          </p>

          <div className="flex justify-start">
            <a
              href="https://apps.apple.com/us/app/trust-crypto-bitcoin-wallet/id1288339409"
              target="_blank"
              rel="noopener noreferrer"
              className=" bg-slate-400 hover:bg-slate-600 text-white font-bold py-2 px-4 rounded flex items-center"
            >
              Baixar para iOS
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp&hl=en&gl=US"
              target="_blank"
              rel="noopener noreferrer"
              className="mx-2 bg-slate-400 hover:bg-slate-600 text-white font-bold py-2 px-4 rounded flex items-center"
            >
              Baixar para Android
            </a>
          </div>
        </div>

        <div className="grid grid-cols-2 p-2  gap-1   bg-gradient-to-tr from-[#9751eb] to-[#f8565e] ">
          <SlidComponent
            title={titleslid01}
            descricao={descricaoslid01}
            images={foto01}
          />
          <SlidComponent
            title={titleslid02}
            descricao={descricaoslid02}
            images={foto02}
          />
          <SlidComponent
            title={titleslid03}
            descricao={descricaoslid03}
            images={foto03}
          />
          <SlidComponent
            title={titleslid04}
            descricao={descricaoslid04}
            images={foto04}
          />
          <SlidComponent
            title={titleslid05}
            descricao={descricaoslid05}
            images={foto05}
          />
          <SlidComponent
            title={titleslid06}
            descricao={descricaoslid06}
            images={foto06}
          />

          <SlidComponent
            title={titleslid07}
            descricao={descricaoslid07}
            images={foto07}
          />

          <SlidComponent
            title={titleslid08}
            descricao={descricaoslid08}
            images={foto08}
          />

          <SlidComponent
            title={titleslid09}
            descricao={descricaoslid09}
            images={foto09}
          />

          <SlidComponent
            title={titleslid10}
            descricao={descricaoslid10}
            images={foto10}
          />

          <SlidComponent
            title={titleslid11}
            descricao={descricaoslid11}
            images={foto11}
          />
        </div>
      </div>
    </div>
  );
}

function DesktopView() {
  const columnHeight = {
    col00: "5%",
    col0: "7%",
    col1: "100%",
    col2: "75%",
    col3: "75%",
    col4: "20%",
  };

  const columnWidth = {
    col0: "20%",
    col1: "100%",
    col2: "30%",
  };

  return (
    <div className="flex flex-col  h-screen">
      {/* Colunas do meio */}
      <div className="flex flex-grow">
        {/* Coluna do menu */}

        <div className="w-full md:w-auto bg-gradient-to-tl from-[#9751eb] to-[#f8565e]  ">
          <Menu />
        </div>

        {/* Coluna do conteúdo */}
        <div
          className="w-full md:w-auto bg-gradient-to-tr from-[#9751eb] to-[#f8565e]   border-l-2 border-gray-200"
          style={{ height: columnHeight.col1, width: columnWidth.col1 }}
        >
          <div className="grid grid-cols-2 p-2 w-full gap-1 ">
            <ComprarComponent />

            <MensagemComponent />
          </div>
          <div className="grid grid-cols-1 p-2 gap-1 ">
            <Tabelas />
          </div>
          <div className="grid grid-cols-1 p-2 w-full gap-1 my-2 bg-slate-300">
            <h5 className="my-2 text-[15px] font-bold tracking-tight text-slate-600 dark:slate-600">
              Tutorial de Como receber os BTC
            </h5>
            <p className="text-base text-slate-600 dark:text-gray-400">
              Sugerimos que baixe o{" "}
              <a
                href="https://trustwallet.com/pt-PT"
                target="_blank"
                rel="noopener noreferrer"
                className="text-slate-600 font-bold  "
              >
                Trust Wallet
              </a>{" "}
              para iOS ou Android.
            </p>

            <div className="flex justify-start">
              <a
                href="https://apps.apple.com/us/app/trust-crypto-bitcoin-wallet/id1288339409"
                target="_blank"
                rel="noopener noreferrer"
                className=" bg-slate-400 hover:bg-slate-600 text-white font-bold py-2 px-4 rounded flex items-center"
              >
                Baixar para iOS
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp&hl=en&gl=US"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2 bg-slate-400 hover:bg-slate-600 text-white font-bold py-2 px-4 rounded flex items-center"
              >
                Baixar para Android
              </a>
            </div>
          </div>

          <div className="grid grid-cols-3 p-2  gap-5 ">
            <SlidComponent
              title={titleslid01}
              descricao={descricaoslid01}
              images={foto01}
            />
            <SlidComponent
              title={titleslid02}
              descricao={descricaoslid02}
              images={foto02}
            />
            <SlidComponent
              title={titleslid03}
              descricao={descricaoslid03}
              images={foto03}
            />
            <SlidComponent
              title={titleslid04}
              descricao={descricaoslid04}
              images={foto04}
            />
            <SlidComponent
              title={titleslid05}
              descricao={descricaoslid05}
              images={foto05}
            />
            <SlidComponent
              title={titleslid06}
              descricao={descricaoslid06}
              images={foto06}
            />

            <SlidComponent
              title={titleslid07}
              descricao={descricaoslid07}
              images={foto07}
            />

            <SlidComponent
              title={titleslid08}
              descricao={descricaoslid08}
              images={foto08}
            />

            <SlidComponent
              title={titleslid09}
              descricao={descricaoslid09}
              images={foto09}
            />

            <SlidComponent
              title={titleslid10}
              descricao={descricaoslid10}
              images={foto10}
            />

            <SlidComponent
              title={titleslid11}
              descricao={descricaoslid11}
              images={foto11}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Index() {
  return (
    <div>
      <div className="lg:hidden">
        <MobileView />
      </div>
      <div className="hidden lg:block">
        <DesktopView />
      </div>
    </div>
  );
}

export default Index;
