import { api } from "../../../services/api";

export async function fintechMeusJogos() {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("@sistemabolao:token");
    api
      .get("/api/v1/lista/meus/jogos", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
