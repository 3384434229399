import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BiHomeSmile,
  BiMoneyWithdraw,
  BiMoney,
  BiBookContent,
  BiLogOut,
  BiHistory,
  BiUser,
} from "react-icons/bi";
import { SidebarContext } from "../../contexts/SidebarContext";

const nomedosistema = "SISTEMA BOLÃO";

const Sidebar = () => {
  const navigate = useNavigate();
  const {} = useContext(SidebarContext);
  const [isOpen, setIsOpen] = useState(true);
  const namecliente = localStorage.getItem("@sistemabolao:nomecliente");
  const [isMenuSair, setIsMenuSair] = useState(true);
  const location = useLocation();

  const handleMenuClickDisconect = () => {
    localStorage.clear();
    window.location.reload();
  };

  function getLinkClass(paths) {
    return paths.some((path) => location.pathname.includes(path))
      ? "bg-[#969696] text-gray-200"
      : "text-[#656565]";
  }

  return (
    <aside className="text-gray-300 w-full md:w-64 flex-shrink-0">
      <a
        href="/"
        className="block border-none my-5 font-extrabold text-center text-[20px] text-[#656565]"
      >
        {nomedosistema}
      </a>

      <div className="bg-[#656565] text-gray-300">
        <a className="block text-center text-[13px]">Olá,</a>
        <a className="block font-extrabold text-center text-[18px]">
          {namecliente}
        </a>
      </div>
      <nav className={`${isOpen ? "block" : "hidden md:block"}`}>
        <ul className="space-y-2">
          <hr className="border-2 border-t border-gray-200" />

          <li>
            <a
              href="/dashboard"
              className={`flex items-center py-2 px-4 ${getLinkClass([
                "/dashboard",
              ])}`}
            >
              <BiHomeSmile className="ml-5" size={20} />
              <span className="ml-2  ">Página Inicial</span>
            </a>
          </li>

          <hr className="border-2 border-t border-gray-200" />

          <li>
            <a
              href="/perfil"
              className={`flex items-center py-2 px-4 ${getLinkClass([
                "/perfil",
              ])}`}
            >
              <BiUser className="ml-5" size={20} />
              <span className="ml-2  ">Meu Perfil</span>
            </a>
          </li>

          <hr className="border-2 border-t border-gray-200" />

          <li>
            <a
              href="/jogos"
              className={`flex items-center py-2 px-4 ${getLinkClass([
                "/jogos",
                "/sala/",
              ])}`}
            >
              <BiMoneyWithdraw className="ml-5" size={20} />
              <span className="ml-2">Jogos</span>
            </a>
          </li>

          <hr className="border-2 border-t border-gray-200" />

          <li>
            <a
              href="/meusjogos"
              className={`flex items-center py-2 px-4 ${getLinkClass([
                "/meusjogos",
              ])}`}
            >
              <BiHistory className="ml-5" size={20} />
              <span className="ml-2">Meus Jogos</span>
            </a>
          </li>

          {isMenuSair ? (
            <>
              <hr className="border-2 border-t border-gray-200 my-4" />
              <li onClick={handleMenuClickDisconect}>
                <a className="flex items-center py-2 px-4 text-[#656565] cursor-pointer">
                  <BiLogOut className="ml-5" size={20} />
                  <span className="ml-2">Sair</span>
                </a>
              </li>
            </>
          ) : null}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
