import { useState, createContext } from "react";
import { api } from "../services/api";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext({});

export function AuthContextProvider(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function signAdmin(user) {
    setLoading(true);
    setError("");
    await api
      .post("/api/v1/cliente/sessions", user)
      .then(({ data }) => {
        localStorage.setItem("@sistemabolao:token", data.token);

        navigate("/dashboard");
      })
      .catch(({ response }) => {
        setError(response.data);
      })
      .finally(() => setLoading(false));
  }

  function logout() {
    navigate("/login");
  }

  return (
    <AuthContext.Provider value={{ user, loading, error, signAdmin, logout }}>
      {props.children}
    </AuthContext.Provider>
  );
}
