import React, { useState, useEffect } from "react";

const BankingSaldo = () => {
  return (
    <div className="bg-gradient-to-tl from-[#D3D3D3] to-[#e4e4e4] p-4 rounded-md shadow-md">
      <h2 className="text-[15px] font-semibold  text-[#656565]">Mensagem:</h2>
      <h2 className="text-[10px] font-semibold  text-slate-500">Data:</h2>
      <div className="text-[15px] font-bold text-green-400">...</div>
    </div>
  );
};

export default BankingSaldo;
