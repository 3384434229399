import React from "react";
import "flowbite";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthContextProvider } from "./contexts/AuthContext";
import { SidebarProvider } from "./contexts/SidebarContext";

//Erro
import Error404 from "./pages/Private/error/404";
import DisplayPage from "./pages/Private/error/DisplayPage";

//Protect Router
import RedirectRouteLogin from "./routes/RedirectRouteLogin";
import ProtectedRoute from "./routes/ProtectedRoute";
import Login from "./pages/Public/login/Login";

//private
import Dashboard from "./pages/Private/dashboard/Index";
import OrdemCompra from "./pages/Private/ordemdecompra/Index";
import Pagamentos from "./pages/Private/pagamentos/Index";
import Perfil from "./pages/Private/perfil/Index";
import Jogos from "./pages/Private/jogos/Index";
import MeusJogos from "./pages/Private/meusjogos/Index";
import Sala from "./pages/Private/sala/Index";

function App() {
  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bolão Prêmios</title>
        </Helmet>
      </div>
      <BrowserRouter>
        <SidebarProvider>
          <AuthContextProvider>
            <Routes>
              <Route path="*" element={<Error404 />} />
              <Route path="/err404" element={<Error404 />} />
              <Route path="/errD" element={<DisplayPage />} />
              <Route element={<RedirectRouteLogin />}>
                <Route index element={<Login />} />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route path="*" element={<Error404 />} />
                <Route path="/perfil" element={<Perfil />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/jogos" element={<Jogos />} />
                <Route path="/meusjogos" element={<MeusJogos />} />

                <Route path="/sala/">
                  <Route path=":idsala" element={<Sala />} />
                </Route>

                <Route path="/order/buy" element={<OrdemCompra />} />
                <Route path="/payments" element={<Pagamentos />} />
              </Route>
            </Routes>
          </AuthContextProvider>
        </SidebarProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
