import { Navigate, Outlet } from "react-router-dom";
import jwt from "jwt-decode";

export default function ProtectedRoute({ redirectPath = "/" }) {
  const token = localStorage.getItem("@sistemabolao:token");

  let isValid = false;

  if (token) {
    const unscrypted = jwt(token);
    isValid = !!unscrypted.iat && !!unscrypted.cli_id;
    localStorage.setItem("@sistemabolao:nomecliente", unscrypted.cli_nome);
    localStorage.setItem("@sistemabolao:idcliente", unscrypted.cli_id);
  }

  if (!token || !isValid) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
}
