import React, { useState, useEffect } from "react";
import { formatToBRL } from "brazilian-values";

const TableExample = () => {
  useEffect(() => {
    funcLista();
  }, []);

  const [respp, setResp] = useState([]);
  const [error, setError] = useState("Sem pagamentos no momento...");
  const [textcopyS, setTextcopyS] = useState("");

  async function funcLista() {}

  // Função para copiar o texto para a área de transferência
  function copyToClipboard(text) {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    try {
      document.execCommand("copy");
      setTextcopyS("Código copiado com sucesso!");
    } catch (e) {
      console.error("Erro ao copiar texto:", e);
      setTextcopyS("");
    }
    document.body.removeChild(textField);
  }

  return (
    <div
      className="bg-white text-[15px] p-4 shadow-md rounded-md"
      style={{ maxHeight: "400px", overflowY: "auto" }}
    >
      <h2 className=" font-bold mb-4">Histórico de Pagamentos</h2>

      {error && (
        <div className="text-center my-5 bg-slate-500 border border-slate-100 rounded p-2">
          <div className="text-[13px] text-slate-100">{error}</div>
        </div>
      )}

      {textcopyS && (
        <div className="text-center my-5 bg-green-500 border border-slate-100 rounded p-2">
          <div className="text-[13px] text-slate-100">{textcopyS}</div>
        </div>
      )}

      {!error && (
        <table className="w-full table-auto bg-white text-[13px]">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">Valor</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Expiração</th>
              <th className="px-4 py-2">Código Copia e Cola</th>
              <th className="px-4 py-2"> </th>
            </tr>
          </thead>
          <tbody>
            {respp.map((item, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">
                  {formatToBRL(item.cpo_valor_compra) || ""}
                </td>

                <td
                  className={`border px-4 py-2 ${
                    item.pag_status === 6
                      ? "text-yellow-500"
                      : item.pag_status === 7
                      ? "text-green-500"
                      : ""
                  }`}
                >
                  {item.pag_status === 6
                    ? "Em Análise"
                    : item.pag_status === 7
                    ? "Confirmado"
                    : ""}
                </td>

                <td className="border px-4 py-2">Expira em 24h</td>
                <td className="border px-4 py-2">{item.pag_copiaecola}</td>
                <td
                  className="border text-white px-4 py-2 bg-slate-500 p-2 text-center cursor-pointer"
                  onClick={() => copyToClipboard(item.pag_copiaecola)}
                >
                  Copiar código
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TableExample;
