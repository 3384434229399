import React, { useState, useEffect } from "react";

import { fetchJogosFinalizados } from "../../integration/jogos/finalizados/fetchJogosFinalizados";

const TableExample = () => {
  useEffect(() => {
    funcLista();
  }, []);

  const [respp, setResp] = useState([]);
  const [error, setError] = useState(null);

  async function funcLista() {
    try {
      const resp = await fetchJogosFinalizados();
      setResp(resp);
      setError(null);
    } catch (erro) {
      setResp(null);
      setError(erro);
    }
  }

  return (
    <div
      className="bg-gradient-to-tl from-[#D3D3D3] to-[#e4e4e4] p-4 rounded-md shadow-md"
      style={{ maxHeight: "400px", overflowY: "auto" }}
    >
      <div>
        <h2 className="text-[#656565] text-[15px] font-bold ">
          Histórico de Jogos Finalizados
        </h2>
        <p className="text-[#888888] text-[15px] mb-5">
          Explore uma lista dos jogos que você jogou e finalizou anteriormente.
        </p>
      </div>

      {error && (
        <div className="text-center my-5 bg-[#656565] border border-slate-100 rounded p-2">
          <div className="text-[13px]  text-slate-100">{error}</div>
        </div>
      )}

      {!error && respp && respp.length > 0 && (
        <table className="w-full table-auto rounded-sm bg-[#fbfbfb] text-[13px] text-center text-[#2f2f2f]">
          <thead>
            <tr className="bg-[#969696]">
              <th className="border px-4 py-2">Status</th>
              <th className="border px-4 py-2">Nome</th>
              <th className="border px-4 py-2">Data</th>
            </tr>
          </thead>
          <tbody>
            {respp.map((item, index) => (
              <tr key={index}>
                <td className="border px-4 py-2 text-red-600">Finalizado</td>
                <td className="border px-4 py-2">{item.jog_nome}</td>
                <td className="border px-4 py-2">{item.jog_data_fim}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TableExample;
