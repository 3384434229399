import React, { useState, useEffect } from "react";
import { fetchJogosAtivos } from "../../integration/jogos/ativos/fetchJogosAtivos";
import { BiSolidCheckCircle } from "react-icons/bi";

const TableExample = () => {
  useEffect(() => {
    funcLista();
  }, []);

  const [respp, setResp] = useState([]);
  const [error, setError] = useState(null);
  const [msgNumeroCopiado, setMsgNumeroCopiado] = useState(false);

  async function funcLista() {
    try {
      const resp = await fetchJogosAtivos();
      setResp(resp);
      setError(null);
    } catch (erro) {
      setResp([]);
      setError(erro);
    }
  }

  useEffect(() => {
    const verificarnumerocopiado = localStorage.getItem(
      "@sistemabolao:repetirjogo"
    );
    if (verificarnumerocopiado) {
      setMsgNumeroCopiado(true);
    }
  }, []);

  return (
    <div
      className="bg-gradient-to-tl from-[#D3D3D3] to-[#e4e4e4] p-4 rounded-md shadow-md"
      style={{ maxHeight: "400px", overflowY: "auto" }}
    >
      {msgNumeroCopiado && (
        <div className="grid grid-cols-1 p-2 w-full gap-1 ">
          <div
            className="bg-green-300  p-4 rounded-md shadow-md"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            <div className="flex items-center">
              <BiSolidCheckCircle className="text-green-700 mr-2" size={24} />
              <h2 className="text-slate-600 font-bold text-[15px]">
                Selecione um jogo abaixo para repetir os números selecionados
              </h2>
            </div>
          </div>
        </div>
      )}

      <div>
        <h2 className="text-[#656565] text-[15px] font-bold">
          Jogos Disponíveis: ({respp.length})
        </h2>
        <p className="text-[#888888] text-[14px]  mb-2">
          Explore a lista de jogos disponíveis para jogar agora mesmo.
        </p>
      </div>

      {error && (
        <div className="text-center my-5 bg-[#656565] border border-slate-100 rounded p-2">
          <div className="text-[13px]  text-slate-100">{error}</div>
        </div>
      )}

      <div className="grid grid-cols-4 gap-4 p-2">
        {respp.map((jogo) => {
          const statusBackgroundClass =
            jogo.jog_status === 3 ? "bg-green-500" : "bg-yellow-500";
          const statusTextClass =
            jogo.jog_status === 3 ? "text-green-500" : "text-yellow-500";
          const statusText = jogo.jog_status === 3 ? "Ativo" : "Inativado";

          return (
            <div
              key={jogo.jog_id}
              className="max-w-sm p-6 bg-[#fbfbfb] border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
            >
              {jogo.jog_status !== 4 ? (
                <a href={`/sala/${jogo.jog_id}`}>
                  <h5 className="mb-2 text-[15px] uppercase font-bold tracking-tight text-[#656565] dark:text-white">
                    {jogo.jog_nome}
                  </h5>
                </a>
              ) : (
                <a>
                  <h5 className="mb-2 text-[15px] uppercase font-bold tracking-tight text-[#656565] dark:text-white">
                    {jogo.jog_nome}
                  </h5>
                </a>
              )}

              <div className="mb-3 text-[15px] font-normal text-gray-700 dark:text-gray-400 flex items-center">
                <div
                  className={`w-3 h-3 rounded-full animate-pulse mr-2 ${statusBackgroundClass}`}
                ></div>
                <span className={`text-[15px] ${statusTextClass}`}>
                  {statusText}
                </span>
              </div>
              <p className="text-[15px] font-normal text-gray-700 dark:text-gray-400">
                Início: {jogo.jog_data_inicio}
              </p>
              <p className="mb-3 text-[15px] font-normal text-gray-700 dark:text-gray-400">
                Fim: {jogo.jog_data_fim}
              </p>

              {jogo.jog_status !== 4 ? (
                <a
                  href={`/sala/${jogo.jog_id}`}
                  className="inline-flex items-center px-3 py-2 text-[15px] font-medium text-center text-white bg-[#656565] rounded-lg"
                >
                  Jogar
                  <svg
                    className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 5h12m0 0L9 1m4 4L9 9"
                    />
                  </svg>
                </a>
              ) : (
                <a className="   inline-flex items-center px-3 py-2 text-[15px] font-medium text-center text-white bg-[#b9b9b9] rounded-lg">
                  Aguarde...
                </a>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableExample;
