import { api } from "../../services/api";
import { toast } from "react-toastify";

export async function fetchAlterarSenha(novasenha, repetirsenha) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("@sistemabolao:token");
    api
      .post(
        "/api/v1/cliente/alterar/senha",
        {
          novasenha,
          repetirsenha,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data);
        reject(error.response.data);
      });
  });
}
