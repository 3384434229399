/**
 *
 *  baseURL:
 */
import axios from "axios";

export function getAPIClient() {
  const api = axios.create({
    baseURL: "http://localhost:8080",
  });

  api.interceptors.request.use((config) => {
    return config;
  });

  return api;
}

export const api = getAPIClient();
