import { Navigate, Outlet } from "react-router-dom";
import jwt from "jwt-decode";

export default function RedirectRouteLogin({
  redirect1 = "/dashboard",
  children,
}) {
  const token = localStorage.getItem("@sistemabolao:token");

  let isValid = false;

  if (token) {
    const unscrypted = jwt(token);

    isValid = unscrypted.iat && unscrypted.cli_id;
  }
  //trader
  if (isValid) {
    return <Navigate to={redirect1} replace />;
  }

  return <Outlet />;
}
