import React, { useState } from "react";
import { fetchComprarOrdem } from "../../integration/comprarordem/fetchComprarOrdem";

const SimuladorInvestimento = () => {
  const [valorInvestido, setValorInvestido] = useState("");
  const [wallet, setWallet] = useState("");
  const [lucro, setLucro] = useState("");
  const [error, setError] = useState("");
  const [sucess, setSucess] = useState("");

  const calcularLucro = (valor) => {
    const valorFloat = parseFloat(valor.replace(",", "."));
    if (!isNaN(valorFloat)) {
      const lucroCalculado = valorFloat * 0.7721; // Calcular o lucro
      const valorfinal = valorFloat + lucroCalculado;
      setLucro(valorfinal.toFixed(2)); // Definir o lucro formatado para duas casas decimais
    } else {
      setLucro("");
    }
  };

  const handleValorChange = (e) => {
    const valor = e.target.value.replace(",", ".");
    setValorInvestido(valor);
    calcularLucro(valor);
  };

  const handleWalletChange = (e) => {
    setWallet(e.target.value);
  };

  async function funcComprarOrdem() {
    if (parseFloat(valorInvestido) < 500) {
      setError("O mínimo de compra é de R$500,00");
    } else {
      try {
        const resp = await fetchComprarOrdem(lucro, wallet, valorInvestido);
        setError("");
        setSucess(
          `Enviamos sua ordem de compra para análise com sucesso e retornaremos o mais breve possível. O valor enviado após aprovação é de R$ ${lucro}.`
        );
      } catch (erro) {
        setSucess("");
        setError(erro.message);
      }
    }
  }

  return (
    <div className="mx-auto w-full p-4 bg-white shadow-md rounded-md">
      <>
        <h2 className="text-slate-500 text-[15px] font-bold">Comprar Ordem</h2>
        <h2 className="text-slate-500 text-[15px] mb-4">
          Insira as informações abaixo para comprar uma ordem
        </h2>
      </>

      {error && (
        <div className="text-center my-5 bg-red-500 border border-slate-100 rounded p-2">
          <div className="text-[13px] text-slate-100">{error}</div>
        </div>
      )}

      {sucess && (
        <div className="text-center my-5 bg-green-400 border border-slate-100 rounded p-2">
          <div className="text-[13px] text-slate-100">{sucess}</div>
        </div>
      )}
      {!sucess && (
        <div>
          <div className="mb-4 text-[15px]">
            <label htmlFor="valorInvestido" className="block mb-2">
              Depositar:
            </label>
            <input
              type="number"
              id="valorInvestido"
              className="border p-2 rounded-md w-full"
              value={valorInvestido}
              onChange={handleValorChange}
              placeholder="R$ 0.00"
            />
          </div>
          <div className="mb-4 text-[15px]">
            <label htmlFor="wallet" className="block mb-2">
              Receber na Wallet:
            </label>
            <input
              type="text"
              id="wallet"
              className="border p-2 rounded-md w-full"
              value={wallet}
              onChange={handleWalletChange}
            />
          </div>
          <p className="mt-4 text-[13px] text-slate-500">A receber:</p>
          <p className="p-1 text-[13px] text-slate-100 bg-green-400 rounded-md">
            R$ {lucro}
          </p>
          <button
            className="mt-5 text-[15px] bg-gradient-to-tr from-[#9751eb] to-[#f8565e] text-white font-bold py-2 px-4 rounded"
            onClick={funcComprarOrdem}
          >
            Enviar Ordem
          </button>
        </div>
      )}
    </div>
  );
};

export default SimuladorInvestimento;
