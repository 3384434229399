import { api } from "../../services/api";
import { toast } from "react-toastify";

export async function fetchComprarOrdem(lucro, wallet, valorInvestido) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("@horseswap:token");
    let nomecliente = localStorage.getItem("@horseswap:nameclient");
    let idcliente = localStorage.getItem("@horseswap:idclient");
    api
      .post(
        "/order/buy",
        {
          cpo_nomecliente: nomecliente,
          cpo_idcliente: idcliente,
          cpo_valor_compra: valorInvestido,
          cpo_valor_receber: lucro,
          cpo_wallet: wallet,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data);
        reject(error.response.data);
      });
  });
}
