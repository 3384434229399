import React, { useState, useEffect } from "react";
import { formatToBRL } from "brazilian-values";

import { fetchBankingSaldo } from "../../../integration/banking/fetchBankingSaldo";

const BankingSaldo = () => {
  useEffect(() => {
    funcCount();
  }, []);

  const [saldo, setSaldo] = useState(0);

  async function funcCount() {
    try {
      const resp = await fetchBankingSaldo();
      setSaldo(resp.total_valor_receber);
    } catch (erro) {
      setSaldo(0);
    }
  }

  return (
    <div className="bg-gradient-to-tl from-[#D3D3D3] to-[#e4e4e4] p-4 rounded-md shadow-md">
      <h2 className="text-[15px] font-semibold  text-[#656565]">Total Ganho</h2>
      <div className="text-[15px] font-bold text-green-400">
        {formatToBRL(saldo) || ""}
      </div>
      <h2 className="text-[10px] font-semibold  text-slate-400">
        Balance: {saldo}
      </h2>
    </div>
  );
};

export default BankingSaldo;
